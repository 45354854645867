<template>
<validation-observer ref="simpleRules">
  <component>

    <b-card v-if="session > 0">

      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="EditIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Edit Hak Akses
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Akses -->
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group
              label="Akses"
              label-for="akses"
            >
               <validation-provider
              #default="{ errors }"
              name="Akses"
              rules="required"
          >
              <b-form-input
                v-model="userDataInfo[0].hakakses_name"
                type="text"
                placeholder="Input Akses"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
            <b-col
              cols="12"
              md="3"
              lg="3"
            >
              <b-form-group
                label="Status"
                label-for="status"
                label-class="mb-1"
              >
                <b-form-radio-group
                  id="menu_active"
                  v-model="userDataInfo[0].hakakses_status"
                  :options="aktifOptions"
                  value=""
                />
              </b-form-group>
            </b-col>
        </b-row>

        <!-- PERMISSION TABLE -->
        <b-row>
          <b-col
            cols="12"
            md="8"
            lg="8"
          >
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="LockIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">Permission</span>
                </b-card-title>
              </b-card-header>
              <b-table
                striped
                responsive
                class="mb-0"
                :items="permisi"
              >
              <template #cell(access)="{ item }">
                  <b-form-checkbox
                    v-model="item.access"
                    inline
                  />
                  </b-form-checkbox>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>

        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click.prevent="validationForm"
          >
            Save Changes
          </b-button>

          <b-button
            variant="outline-secondary"
            :to="{ name: 'apps-hak-akses-list'}"
          >
            Back
          </b-button>
        </b-col>
      </b-form>
    </b-card>

  </component>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'
import axios from '@axios'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'
import useAppConfig from '@core/app-config/useAppConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import verticalNavMenuLoad from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
    BTable,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
       return {
          selected : '',
        }
  },
  methods: {
     async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('param', 'post_hakAkses_update')
          params.append('hakakses_name_old', this.userDataInfo[0].hakakses_name_old)
          params.append('hakakses_name', this.userDataInfo[0].hakakses_name)
          params.append('hakakses_status', this.userDataInfo[0].hakakses_status)
          params.append('permissionsData', JSON.stringify(this.permisi))
          params.append('modify_by', localStorage.getItem('emp_id'))
          const result = await axios.post(`${useAppConfig().AppUrl}hakAkses/update`, params)

          if (result.data.message === 'Berhasil mengubah data') {
            // verticalNavMenuLoad.load_menu()
            this.$root.$emit('component1') //like this
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Berhasil Disimpan',
                variant: 'success',
              },
            })
            this.$router.push('/apps/hak-akses/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Disimpan!',
                variant: 'danger',
              },
            })
            console.log(result)
          }
        }
      })
    },        
    },

  setup(props) {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const userDataInfo = ref([])

    const permisi = ref([])

    onMounted(async () => {
      // const result = await axios.post(`${useAppConfig().AppUrl}hakakses/edit`, { hakakses_name: router.currentRoute.params.hakakses_name })
      const paramsEdit = new URLSearchParams()
              paramsEdit.append('hakakses_name', router.currentRoute.params.hakakses_name)
      const result = await axios.get(`${useAppConfig().AppUrl}hakakses/edit?${paramsEdit}`)
      console.log(router.currentRoute.params.hakakses_name)
      const dataUser = result.data[0]
      const combo = []
      userDataInfo.value = dataUser
      for (let i = 0, len = dataUser.length; i < len; i++) {
        if (dataUser[i].map_hakakses_value == '1'){
            var selected=true
          }
          else {
            var selected=false
          }
          combo.push({ 
            menu: dataUser[i].map_hakakses_menu, 
            urut: dataUser[i].map_hakakses_urut_menu,
            module: dataUser[i].map_hakakses_submenu,
            access: selected,
            route: dataUser[i].map_hakakses_route,
            icon: dataUser[i].map_hakakses_icon,
          })
      }
      permisi.value = combo
    })

    const aktifOptions = [
      { text: 'Aktif', value: '1' },
      { text: 'Tidak Aktif', value: '0' },
    ]

   const permissionsData = [
   {
        menu: 'Master',
        module: 'Master Hak Akses',
        access: false,
      },
      {
        menu: 'Master',
        module: 'Master Divisi',
        access: false,
      },
      {
        menu: 'Master',
        module: 'Master Mapping User',
        access: false,
      },
      {
        menu: 'Master',
        module: 'Master Approval',
        access: false,
      },
      {
        menu: 'Master',
        module: 'Master Kategori Produk',
        access: false,
      },
      {
        menu: 'Master',
        module: 'Master Email Mengetahui',
        access: false,
      },
      {
        menu: 'Sales',
        module: 'Sales List Pengajuan',
        access: false,
      },
      {
        menu: 'Approval Sales',
        module: 'Approval Sales List Pengajuan',
        access: false,
      },
      {
        menu: 'Monitoring',
        module: 'Monitoring List Pengajuan',
        access: false,
      },
    ]

    return {
      session,
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      userDataInfo,
      codeVariant,
      aktifOptions,
      permissionsData,
      permisi,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
